import { Card, Result, Spin, Image, Space } from "antd";
import "antd/dist/antd.css";

function App() {
  return (
    <Card
      hoverable
      style={{
        height: "50hv",
        width: "50vw",
        marginLeft: "25%",
        marginTop: "5%",
      }}
      cover={
        <>
          <Space size="small">
            <Image
              preview={false}
              style={{ width: "40%", marginLeft: "30%" }}
              src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Vt Verde-05.png`}
            />
            <Image
              preview={false}
              style={{ width: "35%", marginLeft: "30%" }}
              src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Smarteco-Verde-05.png`}
            />
          </Space>
        </>
      }
    >
      <Result
        status="500"
        title="Mantenimiento"
        subTitle="Perdone las molestias en este momento la pagina se encuentra en mantenimiento"
        extra={<Spin />}
      />
    </Card>
  );
}

export default App;
